import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { DMEImagesQuery } from '../shared/interfaces';
import * as sharedStyles from '../styles/shared.module.scss';

const DMEPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<DMEImagesQuery>(graphql`
        query {
            dmeBackgroundImage: file(name: {eq: "dmeBackground"}, extension: {eq: "png"}) {
                publicURL
            }
            dmeSummaryImage: file(name: {eq: "dmeSummary"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | DME"/>
            <GeneralJumbotron
                title="Durable Medical Equipment"
                backgroundImageURL={queryResult?.dmeBackgroundImage.publicURL}
                backgroundText="DME"
            />
            <SummaryTemplate image={queryResult?.dmeSummaryImage.publicURL}>
                <h2>{`Durable Medical Equipment`}</h2>
                <p>
                    {`
                        When it comes to DME, it’s often the little things that matter
                        most! Our solution provides equipment and rehab technology
                        that maximize your client’s quality of life while reducing
                        administrative burden for your office.
                    `}
                </p>
                <div className={sharedStyles.listWrapper}>
                    <p>{`Key solution offerings:`}</p>
                    <ul>
                        <li>{`Custom fittings`}</li>
                        <li>{`Postop fittings`}</li>
                        <li>{`Mobility equipment`}</li>
                        <li>{`Orthotics and prosthetics`}</li>
                        <li>{`Medical devices`}</li>
                        <li>{`Medical supplies`}</li>
                    </ul>
                </div>
                <p>
                    {`For a full list of products and services, contact us at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                </p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default DMEPage;
